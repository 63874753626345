import {inject} from 'aurelia-framework';
import {HttpClient} from 'aurelia-fetch-client';
import 'whatwg-fetch';

@inject(HttpClient)
export class BrregLookup {
  httpClient;

  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  brreg(organizationNumber) {
    if (!organizationNumber || organizationNumber.trim().length === 0) {
      return Promise.reject(new Error("Kunne ikke slå opp i brreg: Mangler organisasjonsnummer"));
    }

    return this.httpClient
      .fetch(
        'https://data.brreg.no/enhetsregisteret/api/enheter/' + organizationNumber,
        {
          method: 'GET'
        })
      .then(response => {
        // 200 OK => response body is json.
        if (response.status === 200) {
          // Deserialize and return the payload. json() returns a promise,
          // but returning a promise from a promise handler works fine.
          return response.json();
        }
        // 204 NO_CONTENT => response body is empty because return type is void.
        else if (response.status === 204) {
          return;
        }

        // Should never get here.
        throw new Error("Only status 200 and 204 are supported OK responses, so this isn't expected.")
      })
      .catch(error => {
        if (error instanceof Response) {
          return error.text()
            .then(bodyText => {
              throw new Error("Feilmelding fra brreg.no\n" + bodyText);
            });
        }
        throw error;
      })
      .then(json => {
        return new BrregOrganization(
          json.navn,
          json.organisasjonsnummer,
          json.forretningsadresse.adresse[0],
          json.forretningsadresse.postnummer,
          json.forretningsadresse.poststed,
          json.forretningsadresse.landkode);
      });
  }
}

export class BrregOrganization {
    name;
    organizationNumber;
    streetAddress;
    postalCode;
    city;
    countryCode;

  constructor(name, organizationNumber, streetAddress, postalCode, city, countryCode) {
    this.name = name;
    this.organizationNumber = organizationNumber;
    this.streetAddress = streetAddress;
    this.postalCode = postalCode;
    this.city = city;
    this.countryCode = countryCode;
  }
}
