import Backend from 'backend';
import {bindable, customElement, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {RangeOverrideValueConverter} from 'util/range-override-value-converter';
import numeral from "numeral";
import {NewInstance} from "aurelia-dependency-injection";

@customElement('batch-create-form')
@inject(Element, Backend, Router, NewInstance.of(ValidationController))
export class BatchCreateForm {
  element;
  backend;
  validationController;

  // For dropdowns
  products;

  // To be posted
  batch = {
    reportToHealthDirectorate: true
  };

  postError;
  router;

  @bindable()
  presetProductId;

  constructor(element, backendFacade, router, validationController) {
    this.element = element;
    this.backend = backendFacade;
    this.router = router;
    this.validationController = validationController;
  }

  attached() {
    this.batch.productId = this.presetProductId;
    this.refresh();
  }

  bind() {
    ValidationRules
      .ensure('batchNumber')
        .displayName('Batchnr.')
        .required()
        .then()
        .satisfies(value => Number.isInteger(Number(value))) // Number() converts string to number. Otherwise "1" is not an integer.
        .withMessage('Batchnr. må være et heltall')
      .on(this.batch);
  }

  refresh() {
    return Promise.all([
        this.backend.ProductsForDropdownQueryHandler_handle()
          .then(result => this.products = result.products),
        this.backend.BatchCrud_getMaxBatchNumber()
          .then(result => {
            this.batch.batchNumber = result + 1
          }),
        this.backend.RangeOverridesForDropdownQueryHandler_handle()
          .then(result => {
            this.rangeOverrides = result.rangeOverrides;
            this.rangeOverrides.unshift({id: null, name: 'NONE'});
          }),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
  }

  createSubmit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        return this.backend.BatchCrud_create(this.batch)

          // Event
          .then(batchId => {
            this.element.dispatchEvent(new CustomEvent('batch-saved', {
              detail: {batchId: batchId},
              bubbles: true
            }));
          })

          // Reset all values, form might be used again (in modal f.ex.)
          .then(() => this.batch = { productId: this.presetProductId })

          .catch(error => this.postError = error);
      });
  }

  dismissPostError() {
    this.postError = null;
  }

  translateRangeOverride(value) {
    return RangeOverrideValueConverter.toView(value);
  }
}
