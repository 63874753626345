import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Router)
export class BatchCreateScreen {
  router;

  constructor(router) {
    this.router = router;
  }

  batchSaved() {
    this.router.navigateToRoute('batch-index');
  }
}
